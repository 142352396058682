import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchUserByEmail } from "../../redux/usersSlice";
import { geByUserId, updateReservation, deleteReservation, getallreservation } from "../../redux/reservation/reservationSlice";
import { fetchDoctors } from "../../redux/doctors/doctorsSlice";
import { Tabs, Tab, Typography, Box, Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  styled,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TablePagination,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reservationService from "../../services/reservationService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  "&.MuiTableCell-body": {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ReservationList = () => {
  const [reservations, setReservations] = useState([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [isUpdateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [editedCity, setEditedCity] = useState("");
  const [editedDate, setEditedDate] = useState(new Date());
  const [reservationToUpdate, setReservationToUpdate] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const { reservation, isLoading, error } = useSelector(
    (state) => state.reservation
  );
  const { doctors } = useSelector((state) => state.doctors);

  const fetchReservations = async () => {
    const email = localStorage.getItem("email");
    const user = await dispatch(searchUserByEmail(email));
    const userId = user?.payload?.user?.id;
    const reservationResponse = await dispatch(geByUserId(userId));

    if (reservationResponse.payload) {
      setReservations(reservationResponse.payload);
    }
  };

  const fetchAllReservations = async () => {
    const response = await dispatch(getallreservation());
    setReservations(response.payload);
  };

  useEffect(() => {
    if (tabValue === 0) {
      fetchReservations();
    } else if (tabValue === 1) {
      fetchAllReservations();
    }
  }, [tabValue, dispatch]);

  useEffect(() => {
    dispatch(fetchDoctors());
  }, [dispatch]);

  const getDoctorName = (doctorId) => {
    const doctor = doctors?.find((doctor) => doctor.id === doctorId);
    return doctor?.name ?? "Unknown doctor";
  };

  const isLargeScreen = useMediaQuery("(min-width:768px");

  const handleUpdate = (reservation) => {
    setReservationToUpdate(reservation);
    setEditedCity(reservation.city);
    setEditedDate(new Date(reservation.date));
    setUpdateDialogOpen(true);
  };

  const handleCityChange = (event) => {
    setEditedCity(event.target.value);
  };

  const handleDateChange = (date) => {
    setEditedDate(date);
  };

  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
  };

  const handleDelete = (reservationId) => {
    setReservationToDelete(reservationId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setReservationToDelete(null);
    setDeleteDialogOpen(false);
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
    });
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
    });
  };

  const handleUpdateClick = async () => {
    handleCloseUpdateDialog();
    if (reservationToUpdate) {
      const editedData = {
        city: editedCity,
        date: editedDate,
      };

      try {
        const updatedData = await updateReservationAsync(
          reservationToUpdate.id,
          editedData
        );
        dispatch(updateReservation(updatedData));
        showSuccessToast("Reservation updated successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        showErrorToast("Failed to update reservation");
      }
    }
  };

  //async update
  const updateReservationAsync = async (reservationId, editedData) => {
    try {
      const response = await reservationService.updateReservation(
        reservationId,
        editedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const hasData = reservations?.length > 0;
  const dynamicRowsPerPageOptions = hasData
    ? [Math.min(5, reservations.length), Math.min(15, reservations.length), Math.min(25, reservations.length)]
    : [];

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, reservations.length - page * rowsPerPage);

    const isToday = (someDate) => {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    };
  
    const isTomorrow = (someDate) => {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return (
        someDate.getDate() === tomorrow.getDate() &&
        someDate.getMonth() === tomorrow.getMonth() &&
        someDate.getFullYear() === tomorrow.getFullYear()
      );
    };

    const isYesterday = (someDate) => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return someDate < new Date(yesterday.toDateString());
    };

    const sortedReservations = [...reservations].sort(
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const currentDate = new Date();
    const minTime = new Date(currentDate);
    const maxTime = new Date(currentDate);
    minTime.setHours(currentDate.getHours(), currentDate.getMinutes());
    maxTime.setHours(23, 59);

  return (
    <div className="flex flex-col card shadow-lg m-4 bg-gray-100 mt-4">
      <Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} centered>
        <Tab label="My Reservations" />
        <Tab label="All Reservations" />
      </Tabs>

      <Box mt={2}>
        <div
          className={`flex flex-col card ${
            isLargeScreen ? "w-4/4" : "w-3/4"
          } bg-white p-4 rounded-lg`}
          style={isLargeScreen ? { marginLeft: "16rem" } : {}}
        >
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>City</StyledTableCell>
              <StyledTableCell>Doctor</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? sortedReservations.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : sortedReservations
            ).map((reservation, index) => (
              <StyledTableRow
                key={reservation.id}
                style={{
                  backgroundColor: isToday(new Date(reservation.date))
                    ? 'rgba(255, 0, 0, 0.5)'
                    : isTomorrow(new Date(reservation.date))
                    ? 'rgba(255, 255, 0, 0.3)'
                    : isYesterday(new Date(reservation.date))
                    ? 'rgba(0, 0, 255, 0.8)'
                    : 'inherit',
                 
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{reservation.city}</StyledTableCell>
                <StyledTableCell>
                  {getDoctorName(reservation.doctor_id)}
                </StyledTableCell>
                <StyledTableCell>{reservation.date}</StyledTableCell>
                <StyledTableCell>
                  <IconButton onClick={() => handleUpdate(reservation)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(reservation.id)}>
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
          {hasData && (
        <TablePagination
          rowsPerPageOptions={dynamicRowsPerPageOptions}
          component="div"
          count={reservations?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="mt-4"
          classes={{
            root: 'flex-shrink-0',
            spacer: 'hidden md:flex',
            actions: 'ml-2 md:ml-4',
          }}
        />
      )}
        </div>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle className="bg-red-600 text-white">
          Delete Reservation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this reservation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCloseDeleteDialog();
              if (reservationToDelete) {
                dispatch(deleteReservation(reservationToDelete))
                  .then(() => {
                    showSuccessToast("Reservation deleted successfully");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  })
                  .catch((error) => {
                    showErrorToast("Failed to delete reservation");
                  });
              }
            }}
            color="warning"
            variant="outlined"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUpdateDialogOpen} onClose={handleCloseUpdateDialog} fullWidth maxWidth="sm">
        <DialogTitle className="bg-lime-500 text-white">Edit Reservation</DialogTitle>
        <DialogContent>
          <div className="my-4">
            <label className="block text-gray-600 text-sm font-semibold mb-2">City</label>
            <TextField
              label="City"
              fullWidth
              value={editedCity}
              onChange={handleCityChange}
              variant="outlined"
              className="w-full"
            />
          </div>
          <div className="my-4">
            <label className="block text-gray-600 text-sm font-semibold mb-2">Date</label>
            <DatePicker
              selected={editedDate}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={30}
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={currentDate}
              minTime={minTime}
               maxTime={maxTime}
              className="w-full p-2 border rounded"
            />
          </div>
        </DialogContent>
        <DialogActions className="p-4 bg-white-100">
          <Button onClick={handleCloseUpdateDialog} variant="outlined" color="primary" className="mr-4">
            Cancel
          </Button>
          <Button
            onClick={handleUpdateClick}
            color="primary"
            variant="outlined"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default ReservationList;
